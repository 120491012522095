<template>
	<div class="box">
		<SeasonInput v-if="!season_id" v-model="season" @change="changeSeason" />

		<CustomTable
			v-if="season || season_id"
			id_table="embryons_season"
			:items="embryons"
			:busy.sync="table_busy"
			primaryKey="embryon_id"
			:hrefsRoutes="config_table_hrefs"
			:externSlotColumns="['embryon_stade', 'type_monte']"
		>
			<template v-slot:[`custom-slot-cell(embryon_stade)`]="{ data }">
				{{ $t('gynecologie.embryon.'+data.embryon_stade) }}
			</template>
			<template v-slot:[`custom-slot-cell(type_monte)`]="{ data }">
				{{ $t('actes_type.'+data.type_monte) }}
			</template>
		</CustomTable>

		<b-modal ref="modalEditEmbryon" size="lg" hide-footer>
			<template v-slot:modal-title>
				<template>
					{{ $t("gynecologie.edit_embryon") }}
				</template>
			</template>

			<div>
				<b-checkbox v-model="embryon_congele" class="mr-3 d-inline">
					{{ $t('gynecologie.embryon.congele')}}
				</b-checkbox>

				<b-checkbox v-model="embryon_detruit" class="d-inline">
					{{ $t('gynecologie.embryon.detruit')}}
				</b-checkbox>
			</div>

			<div class="form-group">
				<label for="skip" class="col-form-label">{{ $t("gynecologie.embryon.commentaire") }}</label>
				<input type="text" class="form-control" v-model="embryon_commentaire">
			</div>

			<div class="row mt-2 align-items-center">
				<div class="col-auto">
					<b-input-group :prepend="$t('gynecologie.embryon.taille')">
						<b-input v-model="embryon_taille" />
					</b-input-group>
				</div>
				<div class="mt-3 mt-lg-0 col-lg">
					<b-input-group :prepend="$t('gynecologie.embryon.stade')">
                        <e-select
                            v-model="embryon_stade"
                            :placeholder="getTrad('gynecologie.embryon.select_stade')"
                            :options="all_stades"
                            :searchable="true"
                            :show-labels="false"
						>
							<template slot="option" slot-scope="{ option }">{{ $t('gynecologie.embryon.'+option) }}</template>
							<template slot="singleLabel" slot-scope="{ option }">{{ $t('gynecologie.embryon.'+option) }}</template>
                        	<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</b-input-group>
				</div>
			</div>

			<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="edit">{{ $t("global.modifier") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
		</b-modal>

		<b-modal ref="modalConfirmDeleteEmbryon" hide-footer>
			<template v-slot:modal-title>
				<template>
					{{ $t("gynecologie.embryon.title_suppression") }}
				</template>
			</template>

			<div class="text-center">
				{{ $t("gynecologie.embryon.confirm_suppression") }}<br>

				<b-button class="mt-3 rounded-pill" variant="primary" @click="toDelete">{{ $t("global.supprimer") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
import Embryon from '@/mixins/Embryon'
import Navigation from "@/mixins/Navigation.js";

export default {
	name: 'EmbryonSeason',
	mixins: [Embryon, Navigation],
	props:[
		'horse_id', 'season_id'
	],
	data () {
		return {
			table_busy: true,
			season: null,
			embryons: [],
			embryon: null,
			embryon_id: null,
			embryon_detruit: null,
			embryon_congele: null,
			embryon_commentaire: null,
			embryon_taille: null,
			embryon_stade: '-',
			all_stades: ['-','MOR','JBL','BLA','BLE'],
			processing: false,
			config_table_hrefs: {
				'collecte.horse.horse_nom': {
					routeUniqueName: 'MareFicheInfo',
					routeName: 'HorseFicheInfo',
					params: {
						horse_id: 'collecte.horse.horse_id'
					}
				},
				'collecte.season_mare_stallion.horse.horse_nom': {
					routeUniqueName: 'StallionFicheInfo',
					routeName: 'HorseFicheInfo',
					params: {
						horse_id: 'collecte.season_mare_stallion.horse.horse_id'
					}
				},
				'transplantation.horse.horse_nom': {
					routeUniqueName: 'PorteuseFicheInfo',
					routeName: 'HorseFicheInfo',
					params: {
						horse_id: 'transplantation.horse.horse_id'
					}
				},
				'destinataire.tiers_rs': {
					routeUniqueName: 'tiersFiche',
					routeName: 'tiersFiche',
					params: {
						tiers_id: 'destinataire.tiers_id'
					}
				}
			},
			events_tab: {
				'TableAction::goToEditEmbryon': (embryon) => {
					this.openModal(embryon)
				},
				'TableAction::goToDeleteEmbryon': (embryon) => {
					this.checkTransplantation(embryon)
				}
			}
		}
	},
	mounted() {
		this.init_component()
	},
	methods: {
		async init_component() {
			try {
				this.table_busy = true

				if(this.season_id) {
					this.embryons = await this.getSeasonEmbryonsMare(this.season_id, this.horse_id)
				}
			}
			finally {
				this.table_busy = false
			}
		},
		async changeSeason() {
			try {
				this.table_busy = true

				if(this.season.id) {
					this.embryons = await this.getSeasonEmbryons(this.season.id)
				}
			}
			finally {
				this.table_busy = false
			}
		},
		openModal(embryon) {
			this.embryon_id = embryon.embryon_id
			this.embryon_detruit = embryon.embryon_detruit
			this.embryon_congele = embryon.embryon_congele
			this.embryon_commentaire = embryon.embryon_commentaire
			this.embryon_taille = embryon.embryon_taille
			this.embryon_stade = embryon.embryon_stade
			this.$refs.modalEditEmbryon.show()
		},
		checkTransplantation(embryon) {
			this.embryon_to_delete = embryon
			if(this.embryon_to_delete.transplantation) {
				this.$refs.modalConfirmDeleteEmbryon.show()
			}
			else {
				this.toDelete()
			}
		},
		toDelete() {
			this.deleteEmbryon(this.embryon_to_delete.embryon_id)
			.then(() => {
				this.processing = false
				this.$refs.modalConfirmDeleteEmbryon.hide()
				this.changeSeason()
			})
		},
		edit() {
			this.processing = true
			this.editEmbryon(this.embryon_id, this.embryon_detruit, this.embryon_commentaire, this.embryon_taille, this.embryon_stade, this.embryon_congele)
			.then(() => {
				this.processing = false
				this.$refs.modalEditEmbryon.hide()
				this.changeSeason()
			})
		}
	},
	components: {
		CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
		SeasonInput : () => import('@/components/Inputs/SeasonInput'),
	}
}
</script>
